import { useCallback, useState } from 'react'

import { useRouter } from 'next/router'

import { PractitionersAvailabilities } from 'src/@types/PractitionersAvailabilities'
import {
  setBookingIds,
  setBookingScreenType,
  useBookings,
  useBookingsDispatch,
} from 'src/components/providers/BookingsProvider'
import usePractitionersAvailabilities from 'src/graphql/hooks/usePractitionersAvailabilities'
import { BookingScreenType } from 'src/screens/Appointments/Management/types'

const usePractitionerSelection = (standalone = true) => {
  const router = useRouter()
  const dispatch = useBookingsDispatch()

  const bookRedirect = '/appointments/book'

  const [loading, setLoading] = useState<boolean>(false)

  const { refetch } = usePractitionersAvailabilities({})

  const { practitionerReferenceId } = useBookings()

  const goToBook = () => {
    return standalone ? router.push(bookRedirect) : dispatch(setBookingScreenType(BookingScreenType.Book))
  }

  const setPractitioners = (practitionersAvailabilities?: PractitionersAvailabilities[]) => {
    const practitionerReferenceIds = practitionersAvailabilities?.map((a) => a.practitioner.referenceId)

    if (practitionersAvailabilities) {
      dispatch(
        setBookingIds({
          practitionerReferenceIds,
          practitionerReferenceId: practitionerReferenceIds.length === 1 ? practitionerReferenceIds[0] : null,
        })
      )
    }
  }

  const initPractitionerSelection = useCallback(
    ({
      clinicReferenceId,
      treatmentBundleReferenceId,
      shouldRedirect = true,
    }: {
      clinicReferenceId: string
      treatmentBundleReferenceId: string
      shouldRedirect?: boolean
    }) => {
      setLoading(true)

      refetch({ treatmentBundleReferenceId, clinicReferenceId }).then(({ data }): unknown => {
        setLoading(false)

        if (standalone) {
          const availabilities = data.practitionersAvailabilities
          const favoritePractitionerAvailabilities = availabilities.filter((a) => a.practitioner.favorite)
          const lastVisitedPractitionersAvailabilites = availabilities.filter((a) => !!a.lastVisitedDate)

          if (favoritePractitionerAvailabilities.length) {
            setPractitioners([favoritePractitionerAvailabilities.find((a) => a)])
            shouldRedirect && goToBook()

            return
          }

          if (lastVisitedPractitionersAvailabilites.length === 1) {
            setPractitioners(lastVisitedPractitionersAvailabilites)
            shouldRedirect && goToBook()

            return
          }

          setPractitioners(availabilities)

          return goToBook()
        }

        return goToBook()
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, dispatch, router, practitionerReferenceId, standalone]
  )

  return { initPractitionerSelection, goToBook, loading }
}

export default usePractitionerSelection
